import {
  FC,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  useMap,
  LayersControl,
} from "react-leaflet";
import "leaflet-draw";
import L, { LatLngExpression } from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { SiteTemporal } from "../../store/createSites";
import centroid from "@turf/centroid";
import area from "@turf/area";
import AddIcon from "@mui/icons-material/Add";
import MapSearchBar, { GeoGouvCommune } from "./SearchBar";

L.drawLocal.draw.handlers.polygon.tooltip.start =
  "Cliquez pour commencer à dessiner";

const MapBaseContainer = styled("div")(({ theme }) => ({
  background: "#ccc",
  height: "100%",
  width: "100%",
}));

interface MapBaseProps {
  editable?: boolean;
  children: ReactNode;
  onInit?(map: L.Map, featureGroup: MutableRefObject<L.FeatureGroup>): void;
  onCreate?(siteTemporal: SiteTemporal): void;
}

const MapEdit: FC<MapBaseProps> = ({
  editable = false,
  onInit = () => {},
  onCreate,
  children,
}) => {
  const map = useMap();
  const featureGroup = useRef() as MutableRefObject<L.FeatureGroup>;

  const handleSiteCreated = async (e: any) => {
    const geojson = e.layer.toGeoJSON();
    const coordinates = [geojson.geometry.coordinates[0]];

    const data = {
      geom: {
        coordinates: coordinates,
        type: geojson.geometry.type,
      },
      centroid: {
        type: "Point",
        coordinates: centroid(geojson).geometry.coordinates.slice(),
      },
      area: area(geojson),
      layer: e.layer,
      management_practices: {
        seeding: "",
        cuttingType: "",
        cuttingPeriod: "",
        cuttingFrequency: "",
        residueExportation: "",
        pastureType: "",
        pasturePressure: 0,
        pastureSize: 0,
        pastureDuration: 0,
        phytosanitary: "",
        uncontrolledManagementPressure: "",
      },
    };
    onCreate && onCreate(data);
  };

  const onDrawCreate = (e: { layer: L.Layer }) => {
    map.addLayer(e.layer);
  };

  useEffect(() => {
    if (editable) {
      map.on("draw:created", handleSiteCreated);
      map.on(L.Draw.Event.CREATED, onDrawCreate);
    }

    onInit(map, featureGroup);

    return () => {
      if (editable) {
        map.off(L.Draw.Event.CREATED, onDrawCreate);
        map.off("draw:created", handleSiteCreated);
      }
    };
  }, [editable]);

  return (
    <>
      <FeatureGroup ref={featureGroup}>{children}</FeatureGroup>
      {editable && (
        <div className="leaflet-bottom leaflet-right">
          <div className="leaflet-control leaflet-bar">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                const handler = new L.Draw.Polygon(map as L.DrawMap, {});
                handler.enable();
              }}
            >
              <AddIcon /> Ajouter un site
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const MapControls = () => {
  const map = useMap();
  const { BaseLayer } = LayersControl;

  const handleOnSearchBarChange = (option: GeoGouvCommune) => {
    map.flyTo(option.centre.coordinates.reverse() as LatLngExpression, 15);
  };

  return (
    <>
      <div className="leaflet-bottom leaflet-left">
        <div className="leaflet-control leaflet-bar">
          <MapSearchBar onChange={handleOnSearchBarChange} />
        </div>
      </div>
      <LayersControl>
        <BaseLayer name="OSM">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer checked name="IGN-F/Géoportail">
          <TileLayer
            attribution='&copy; <a href="https://www.ign.fr/">IGN</a> contributors'
            url="https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=image/jpeg&STYLE=normal"
          />
        </BaseLayer>
      </LayersControl>
    </>
  );
};

const MapBase: FC<MapBaseProps> = ({
  onInit,
  editable = false,
  children,
  onCreate,
}) => {
  return (
    <MapBaseContainer>
      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          minHeight: "calc(50vh - 64px)",
        }}
        center={[46.71109, 1.7191]}
        zoom={6}
      >
        <MapControls />

        <MapEdit onInit={onInit} editable={editable} onCreate={onCreate}>
          {children}
        </MapEdit>
      </MapContainer>
    </MapBaseContainer>
  );
};

export default MapBase;
