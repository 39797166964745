import { FC, ReactNode } from "react";
import ContainerTitle from "../commons/ContainerTitle";
import useStore from "../../store/useStore";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import CommonsListItemCoordinates from "../commons/ListItemCoordinates";
import CommonsMetadataList, {
  RecordMetadataItem,
} from "../commons/MetadataList";
import { handleGoBack } from "../../lib/navigateBack";
import IsNotVisibleAlert from "../commons/IsNotVisibleAlert";
interface SessionMetadataProps {
  hasTitle?: boolean;
}

const SessionMetadata: FC<SessionMetadataProps> = ({ hasTitle = true }) => {
  const navigate = useNavigate();
  const { session, user, groups } = useStore((state) => state);

  const items: (RecordMetadataItem | ReactNode)[] = [
    {
      primary: "id de la session",
      secondary: session?.uuid,
      visible: !hasTitle,
    },
    {
      primary: "Organisme",
      secondary: groups.find((g) => g.id === session?.group_id)?.name,
    },
    {
      primary: "Nom du site",
      secondary: (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/sites/${session?.site.uuid}`);
          }}
        >
          {session?.site.name}
        </Link>
      ),
    },
    {
      primary: "Utilisateur",
      secondary: session?.site.owner_info,
    },
    {
      primary: "Date de création",
      secondary: session?.sunset_date
        ? new Date(session.sunset_date).toLocaleDateString()
        : null,
    },
    {
      primary: "Heure du coucher de soleil",
      secondary: session?.sunset_date
        ? new Date(session.sunset_date).toLocaleTimeString()
        : null,
    },
    <CommonsListItemCoordinates
      lat={session?.geom?.coordinates[1]}
      long={session?.geom?.coordinates[0]}
    />,
    {
      primary: "Dispositif",
      secondary: session?.device.uuid,
    },
  ];

  return hasTitle ? (
    <ContainerTitle
      title={`Session nº ${session?.uuid}`}
      onClose={() => handleGoBack("/sessions", navigate)}
    >
      <IsNotVisibleAlert site={session?.site} user={user} />
      <CommonsMetadataList items={items} />
    </ContainerTitle>
  ) : (
    <>
      <IsNotVisibleAlert site={session?.site} user={user} />
      <CommonsMetadataList items={items} />
    </>
  );
};

export default SessionMetadata;
