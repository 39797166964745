import { FC } from "react";
import { Button, Box, Typography } from "@mui/material";
import useStore from "../../store/useStore";
import { ImageRead } from "../../client";
import { useNavigate } from "react-router-dom";

const styles = {
  stackContainer: {
    minWidth: "164px",
    minHeight: "164px",
    width: "164px",
    height: "164px",
    borderRadius: "5px",
    overflow: "hidden",
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "info.main",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    alignItems: "end",
  },
  imageCaption: {
    background: "rgba(0,0,0,7)",
    color: "#ffff",
    p: 1,
    width: "100%",
    overflow: "hidden",
  },
};

export interface ThumbnailProps {
  active?: boolean;
  link?: boolean;
  image: ImageRead | undefined;
  caption?: boolean;
}

const Thumbnail: FC<ThumbnailProps> = ({
  active = false,
  link = true,
  image,
  caption,
}) => {
  const navigate = useNavigate();
  const { setImageSelected, sessionGet, session } = useStore((state) => state);

  const handleOnClick = () => {
    setImageSelected(image);
    image?.session?.uuid &&
      image?.session?.uuid !== session?.uuid &&
      sessionGet(image?.session?.uuid);

    link &&
      navigate(
        `/gallery?group=${image?.group_id ? image?.group_id : ""}&site=${
          image?.session?.site_id ? image.session.site_id : ""
        }&session=${image?.session?.uuid!}`
      );
  };

  return (
    <Box sx={styles.stackContainer}>
      <Button
        fullWidth
        sx={{
          p: 0,
          height: "100%",
          border: active ? "4px solid #F2CC38" : "none",
        }}
      >
        <Box
          sx={[
            styles.imageContainer,
            { backgroundImage: `url(${image?.tn_url})` },
          ]}
          onClick={handleOnClick}
        >
          {caption && (
            <Typography sx={styles.imageCaption}>
              {image?.created_date
                ? new Date(
                    String(image?.session?.created_date)
                  ).toLocaleDateString()
                : "chargement"}
            </Typography>
          )}
        </Box>
      </Button>
    </Box>
  );
};
export default Thumbnail;
